﻿export class FormatHelper {

    // CAS-51291 NGP - reports - date format and weekly aggregation
    private static forceLanguage = "en-GB"; // => format dd/mm/yy

    public static shortDate(value: any, language: string = navigator.language): string {
        return (new Date(value)).toLocaleDateString(this.forceLanguage, {day: "numeric", month: "numeric" , year: "2-digit" });
    }

    public static shortDateAndTime(value: any, language: string = navigator.language): string {
        const date = new Date(value),
            res = date.toLocaleDateString(this.forceLanguage, {day: "numeric", month: "numeric" , year: "2-digit" })
                 + " " + date.toLocaleTimeString(this.forceLanguage, { hour12: false });
        return res;
    }
     
    public static shortTime(value: any, language: string = navigator.language): string {
        const date = new Date(value),
            res = date.toLocaleTimeString(this.forceLanguage, { hour12: false });
        return res;
    }

    public static currency(value: any, currency: string, language: string = navigator.language, currencyClassification: string, maxFractionDigits: number = 2): string {
        let currencyValue = (value ? value : 0 as number);
       
        const decimalsToDisplay = (maxFractionDigits === 8) ? maxFractionDigits : (currency === 'MBT') ? 5 : maxFractionDigits;
        
        if (currencyClassification === undefined || currencyClassification === null) {
            currencyClassification = 'Thousands';
        }

        try {
            currencyValue = currencyValue.toLocaleString(language, { style: "currency", currency: currency, maximumFractionDigits: decimalsToDisplay, minimumFractionDigits: 2 });
        } catch (err) {
            currencyValue = currencyValue.toLocaleString(language, {maximumFractionDigits: decimalsToDisplay, minimumFractionDigits: 2});
        }
        if (currency === "KRW" && currencyClassification === "Thousands") {
            currencyValue = currencyValue.replace("₩", "");
            currencyValue = currencyValue.concat("천원");
        }	
        else if (currency === "IDR" && currencyClassification === "Thousands") {
            currencyValue = currencyValue.replace("IDR", "IDR k");
        }
        else if (currency === "VND" && currencyClassification === "Thousands") {
            currencyValue = currencyValue.replace("₫", "");
            currencyValue = currencyValue.concat("k");
        }        
        else if (currency === "IRR" && currencyClassification === "Thousands") {
            currencyValue = currencyValue.replace("﷼", "");
            currencyValue = currencyValue.replace("IRR", "");
            currencyValue = currencyValue.concat("k");
        }
        else if (currency === "MMK" && currencyClassification === "Thousands") {
            currencyValue = currencyValue.replace("MMK", "");
            currencyValue = currencyValue.concat("k");
        }          
        else if (currency === "NOK") {
            currencyValue = currencyValue.replace("NOK", "");
            currencyValue = currencyValue.concat(" kr");
        }          
        else if (currency === "HKD") {
            currencyValue = currencyValue.replace("HK", "");
        }
        else if (currency === "KHR" && currencyClassification === "Thousands") {
            currencyValue = currencyValue.replace("KHR", "");
            currencyValue = currencyValue.concat("k");
        }
        else if (currency === "UGX" && currencyClassification === "Thousands") {
            currencyValue = currencyValue.replace("UGX", "");
            currencyValue = currencyValue.concat("k");
        }
        else if (currency === "KZT") {
            currencyValue = currencyValue.replace("KZT", "₸");
        }
        else if (currency === "UZS" && currencyClassification === "Thousands") {
            currencyValue = currencyValue.replace("UZS", "");
            currencyValue = currencyValue.concat("k");
        }
        else if (currency === "AMD") {
            currencyValue = currencyValue.replace("AMD", "֏");
        }
        else if (currency === "SEK") {
            currencyValue = currencyValue.replace("SEK", "");
            currencyValue = currencyValue.concat("kr");
        }
        else if (currency === "CZK") {
            currencyValue = currencyValue.replace("CZK", "");
            currencyValue = currencyValue.concat(" Kč");
        }
        else if (currency === "LKR") {
            currencyValue = currencyValue.replace("LKR", "Rs");
        }
        else if (currency === "MXN") {
            currencyValue = currencyValue.replace("MXN", "$");
        }
        else if (currency === "LAK" && currencyClassification === "Thousands") {
            currencyValue = currencyValue.replace("LAK", "");
            currencyValue = currencyValue.concat("k");
        }
        else if (currency === "NGN") {
            currencyValue = currencyValue.replace("NGN", "₦");
        }
		else if (currency === "CHP") {
            currencyValue = currencyValue.replace("CHP", "$");
        }
        else if (currency === "BDT") {
            currencyValue = currencyValue.replace("BDT", "৳");
        }
	    else if (currency === "NPR") {
            currencyValue = currencyValue.replace("NPR", "रु");
        }        
        else if (currency === "MBT") {
            currencyValue = currencyValue.replace("-", "").replace(/,/g, "");
            currencyValue = `${currency} ${(+currencyValue.slice(4)).toFixed(decimalsToDisplay)}`;
            currencyValue = currencyValue.replace("MBT", "m₿");
        }
        else if (currency === "GHS") {
            currencyValue = currencyValue.replace("GHS", "₵");
        }
        else if (currency === "UYU") {
            currencyValue = currencyValue.replace("UYU", "$");
        }
        else if (currency === "COP"){
            currencyValue = currencyValue.replace("COP", "$");
        }
        else if (currency === "PYG") {
            currencyValue = currencyValue.replace("PYG", "₲");
        }

        return parseFloat(value) < 0 ? `(${currencyValue.replace("-", "")})` : currencyValue;
    }

    public static round(value: any): string {
        return (value ? parseFloat(value) : 0 as number).toFixed(2);
    }

    public static percentage(value: any, formatParam?: any): string {
        return `${(value ? value : 0 as number).toFixed(1)}%`;
    }

    public static isString(obj : any) {
        return (typeof obj === 'string' || obj instanceof String);
    }
}
